
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import { Component } from 'vue-property-decorator';
import ForgotPasswordAuthCodeRequest from '@/Interfaces/ForgotPasswordAuthCodeRequest';
import ForgotPasswordRequest from '@/Interfaces/ForgotPasswordRequest';
import { LoginUserRequest } from '@/contracts/LoginUserReqest';
import LocalStorageService from '../services/LocalStorageService';
import RoleService from '../services/RoleService';
import router from '../router';

export default class ForgotPassword extends Vue {
  localStorageService: LocalStorageService = new LocalStorageService();
  roleService: RoleService = new RoleService(this.localStorageService);
  /** comment */
  loading = false;
  fromError = false;
  messageText = '';
  disablebutton = false;
  hideFileds = false;
  hideSendButton = true;
  resendButton = false;
  confirmPasswordErr = false;
  passwordError = false;
  email = '';
  authCode = '';
  newPassword = '';
  confirmPassword = '';
  fieldTypes: any = {
    password: 'text',
  };

  mounted() {
    this.loading = false;
    this.disablebutton = false;
    const userToken = this.localStorageService.getItem('user-token');
    const roles = this.localStorageService.getItem('roles');
    if (userToken) {
      if (roles === 'patient') {
        router.push({
          path: `/orders`,
        });
      } else {
        router.push({
          path: `/orders/new`,
        });
      }
    }
    if (this.$route.query.email) {
      const email = this.$route.query.email.toString();
      this.email = email;
    }
  }

  public async sendAuthCode() {
    this.loading = true;
    this.fromError = false;
    if (this.authCodetFormValidation()) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}/authenticate/forgot-password`,
        data: this.getAuthData(),
        method: 'POST',
      })
        .then((resp) => {
          this.loading = false;
          this.hideFileds = true;
          this.hideSendButton = false;
          setTimeout(() => {
            this.resendButton = true;
          }, 60000);
        })
        .catch((err) => {
          this.loading = false;
          this.hideFileds = false;
          this.fromError = true;
          this.messageText = 'Invalid credentials. Please try again!';
          const erorr = err;
        });
    } else {
      this.loading = false;
      this.fromError = true;
      this.messageText = 'Please enter email Id!';
    }
  }

  getAuthData() {
    const authData: ForgotPasswordAuthCodeRequest = {
      email: this.email,
    };
    return authData;
  }
  public async loginPage() {
    router.push({ path: '/login' });
  }
  public async resetPassword() {
    this.loading = true;
    this.fromError = false;
    this.confirmPasswordErr = false;
    this.passwordError = false;
    if (this.forgotPasswordFormValidation()) {
      axios({
        url: `${process.env.VUE_APP_BASE_URL}/authenticate/update-password`,
        data: this.getForgotPasswordAuthData(),
        method: 'POST',
      })
        .then((resp) => {
          this.loading = false;
          router.push({
            path: '/login',
            query: {
              email: this.email,
            },
          });
        })
        .catch((err) => {
          this.loading = false;
          this.fromError = true;
          this.messageText = 'Invalid credentials. Please try again';
          const erorr = err;
        });
    } else {
      this.loading = false;
      this.fromError = true;
      if (this.confirmPasswordErr) {
        this.messageText = 'Password does not match';
      } else if (this.passwordError) {
        this.messageText =
          'Password must contain atleast 8 characters, one special character (i.e !@#$%^&*), one number and one uppercase letter';
      } else {
        this.messageText = 'Please enter all details';
      }
    }
  }

  getForgotPasswordAuthData() {
    const frgotPasswordAuthData: ForgotPasswordRequest = {
      email: this.email,
      password: this.newPassword,
      authCode: this.authCode,
    };
    return frgotPasswordAuthData;
  }

  authCodetFormValidation() {
    if (!this.email) {
      return false;
    }
    return true;
  }

  forgotPasswordFormValidation() {
    if (
      this.email &&
      this.newPassword &&
      this.authCode &&
      this.confirmPassword
    ) {
      //verify if password has atleast 8 characters, one special character (i.e !@#$%^&*), one number and one uppercase letter
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
      if (passwordRegex.test(this.newPassword)) {
        if (this.newPassword === this.confirmPassword) {
          return true;
        } else {
          this.confirmPasswordErr = true;
          return false;
        }
      } else {
        this.passwordError = true;
        return false;
      }
    } else {
      return false;
    }
  }
  handleType(event: any) {
    const { srcElement, type } = event;
    const { name, value } = srcElement;
    if (type === 'blur' && !value) {
      this.fieldTypes[name] = 'text';
    } else {
      this.fieldTypes[name] = 'password';
    }
  }
}
